.slider{
    height: 40px;
    border: 2px solid red;
    border-radius: 20px;
}

.thumb{
    background-color: red;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 20px;

}

.slider.green{
    border-color: green;
}

.slider.green .thumb{
    background-color:  green;
}


.timer-settings-button {
    outline: none;
    border: none;
    color: white;
}

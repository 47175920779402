html{
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
  font-family: interMedium;
}




.masterychecklist{
  color: white;
  margin: 0;
  font-family: interRegular;
  padding-left: 10px;
  padding-top: 40px;
}

@font-face{
  font-family: interMedium;
  src: url(../public/Fonts/Inter-Medium.ttf);
}
@font-face{
  font-family: interSemiBold;
  src: url(../public/Fonts/Inter-SemiBold.ttf);
}
@font-face{
  font-family: interRegular;
  src: url(../public/Fonts/Inter-Regular.ttf);
}

.App{
  height: 100vh;
  width: 100%;
  background-image: url(./Images/dojo\ 1\ up\ Pandad.jpg);
  background-size: cover;
  background-position: bottom ;
  
} 

/* Switch and settings menu*/
.switch{
  margin-top: 10px;
  margin-left: 15px;
  position: absolute;
  left: 0;
  color: white;
  display: flex;
  align-items: center;

}

.react-switch{
  background-color: rgba(217, 217, 217, 0.35) ;

}

/* .react-switch .react-switch-bg{
  
} */
/* Timer Style */

.timer-button{
  background-color:  transparent;
  color:  rgba(255,255,255,.8);
  border:   0;
  width: 80px;
  cursor: pointer;
}
.timer-settings-button{
  width: 130px;
  background-color: rgba(255,255,255,.1);
  border-radius: 10px;
  padding: 5px 5px;
  font-size: 1.1rem;
  line-height: 36px;
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
}
.timer-settings-button svg{
  width: 36px;
  float: left;
  margin-right: 3px;
  


}

.pomodoro-cont{
  height: 500px;
  width: 14vw;
  position: absolute;
  margin-top: 50px;
  color: white;
  top: 0;
  margin-left: 15px;
  margin-top: 100px;
  padding-left: 20px;
}

.btn-cont{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-direction: row;
}
@media all and (width < 1186px){ 
  .btn-cont{
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  .pomodoro-cont{
    padding-left: 0;
  }
}

@media all and (width < 1037px){
  .pomodoro-cont{
    top: 0;
    height: 300px;
    width: 300px;
    align-self: center;
    margin: 0;
    margin-top: 100px;
    position: absolute;
    visibility: hidden;
    



    
  }




}



.timer-settings label{
  display: block;
  margin-bottom: 10px;
  margin-top: 20px;
  text-transform: capitalize;
}


.taskRectangle{
  margin: auto;
  min-height: 100vh;
  height: auto;
  max-width: 702px;
  background-color: rgba(0, 0, 0, 0.29);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  
  
  
  
}

/* Log Buttons */




/* Task Style */

.task-div{
  display: flex;
  justify-content: space-between;
  color: white;
  
  background-color: rgba(217, 217, 217, 0.35);
  margin-bottom: 20px;
  border-radius: 32px;
  border: none;
  padding-left: 10px;
  min-height: 47px;;

  font-family:interRegular ;

}
.task-text{
  /*add later */
  cursor: pointer;
  font-family: interRegular;
  flex-grow: 1;
  
}
.div-done-class{
  background-color: green;
  opacity: 45%;
}
.scores{
  display: flex;
  font-family: interMedium;
  
  align-items: center;
}
.score {
    width: 40px;
    color: rgba(255,215,0,1);
    padding-left: 10px;
    
}

.taskText{
  cursor: pointer;

}

.imp{
  border-right: solid 2px black;
  padding-right: 0px;

}


.delete-button{
  background-color: rgba(217, 217, 217, 0.5);
  border-radius: 32px;
  color: white;
  height: 34px;
  border: none;
  margin-right: 20px;
  width: 80px;
  cursor: crosshair;
}

.img-under-form{
  
  height: 100px;
  width: 650px;
  align-self: center;
  
}



.signForm{
  display: flex;
  flex-direction: column;
  
}

.done{
  text-decoration: line-through;
  cursor: pointer;
}


/* Reactangle Input*/

.RecFrom{
  padding-top: 10px;
  display: flex;
  font-family: interMedium;
}

.input{
  background-color: rgba(217, 217, 217, 0.35);
  border-radius: 32px;
  color: white;
  height: 47px;
  border: none;
  margin-left: 2px;
  font-family: interMedium;

  font-size: 15px;
  
}

.text-input{ 
    flex-grow: 1;
    padding-left: 10px;
    margin-right:2px ;
    padding-right: 10px;
   
}

.cont-num-inputs{
  flex-shrink: 0;
}

.num-input{
  width: 60px;
  text-align: center;
  margin: 2px;
  
}



input::placeholder {
  font-weight: bold;
  opacity: 0.5;
  color: white;
}

@media all and (width < 600px){
  .RecFrom{
    flex-direction: column;
    flex-wrap: wrap;
    padding-left: 10px;
    padding-right: 10px;
  }
  .cont-num-inputs{
    padding-top: 10px;
    display: flex;
    justify-content: space-around;
    
  }

}

